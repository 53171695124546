import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import '../App.css'; 
import DropdownMenu from './DropdownMenu';

function Portfolio() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <div>Please log in to view the portfolio.</div>;
    }

    const holdings = [
        { equities: "SoFi Technologies", ticker: "SOFI", dividendYield: "--", costBasis: "$39,997", reinvestedDividends: "--", cashDividends: "--", marketValue: "$81,409", portfolioPercent: "19.6%", totalgainloss: "104%" },
        { equities: "AGNC Investment Corp", ticker: "AGNC", dividendYield: "13.8%", costBasis: "$51,644", reinvestedDividends: "$11,186", cashDividends: "$360", marketValue: "$60,095", portfolioPercent: "14.4%", totalgainloss: "17%" },
        { equities: "Pinterest", ticker: "PINS", dividendYield: "--", costBasis: "$30,089", reinvestedDividends: "--", cashDividends: "--", marketValue: "$43,441", portfolioPercent: "10.4%", totalgainloss: "44%" },
        { equities: "AST SpaceMobile", ticker: "ASTS", dividendYield: "--", costBasis: "$21,294", reinvestedDividends: "--", cashDividends: "--", marketValue: "$40,480", portfolioPercent: "9.7%", totalgainloss: "90%" },
        { equities: "PubMatic", ticker: "PUBM", dividendYield: "--", costBasis: "$49,995", reinvestedDividends: "--", cashDividends: "--", marketValue: "$40,416", portfolioPercent: "9.7%", totalgainloss: "(19%)" },
        { equities: "Comtech Telecommunications Corp", ticker: "CMTL", dividendYield: "--", costBasis: "$54,950", reinvestedDividends: "--", cashDividends: "--", marketValue: "$40,000", portfolioPercent: "9.6%", totalgainloss: "(27%)" },
        { equities: "Alphabet", ticker: "GOOGL", dividendYield: "0.5%", costBasis: "$19,940", reinvestedDividends: "--", cashDividends: "$24", marketValue: "$24,482", portfolioPercent: "5.9%", totalgainloss: "23%" },        
        { equities: "Micron Technology", ticker: "MU", dividendYield: "0.5%", costBasis: "$24,775", reinvestedDividends: "--", cashDividends: "--", marketValue: "$23,722", portfolioPercent: "5.7%", totalgainloss: "(4%)" },
        { equities: "Equities Total", ticker: "--", dividendYield: "2.9%", costBasis: "$292,683", reinvestedDividends: "$11,186", cashDividends: "$384", marketValue: "$354,046", portfolioPercent: "85.1%", totalgainloss: "21%" },
        { equities: "Cash", ticker: "--", dividendYield: "--", costBasis: "--", reinvestedDividends: "--", cashDividends: "--", marketValue: "$61,914", portfolioPercent: "14.9%", totalgainloss: "--" },
        { equities: "Portfolio Value", ticker: "--", dividendYield: "2.6%", costBasis: "$332,000", reinvestedDividends: "--", cashDividends: "--", marketValue: "$415,960", portfolioPercent: "100.0%", totalgainloss: "25%" }
    ];


    return (
        <div className="container mt-3">
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h3>Portfolio Holdings (as of 1/31/25)</h3>
                <DropdownMenu />  
            </div>    
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Equities</th>
                        <th>Ticker</th>
                        <th>Dividend Yield</th>
                        <th>Cost Basis</th>
                        <th>Market Value</th>
                        <th>% of Portfolio</th>
                        <th>Total % gain/loss</th>
                    </tr>
                </thead>
                <tbody>
                    {holdings.map((holding, index) => (
                        <tr key={index}>
                            <td>{holding.equities}</td>
                            <td>{holding.ticker}</td>
                            <td>{holding.dividendYield}</td>
                            <td>{holding.costBasis}</td>
                            <td>{holding.marketValue}</td>
                            <td>{holding.portfolioPercent}</td>
                            <td>{holding.totalgainloss}</td>
                        </tr>
                    ))}
                </tbody>
            </table>            
        </div>
    );
}

export default Portfolio;
